import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import BuynowItem from "@components/BuynowItem"
// import { Container } from "reactstrap"
import HeroSwiper from "@components/swiper/hero-swiper"
import Slider from "@components/swiper/swiper"
import {
	LazyLoadImage,
	LazyLoadComponent,
} from "react-lazy-load-image-component"
import "react-lazy-load-image-component/src/effects/blur.css"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"
import ReadMoreAndLess from "react-read-more-less"

import upload from "@images/upload.svg"
import build1 from "@images/build.svg"
import share from "@images/share.svg"

// import online from "@images/001-shop.svg"

import works from "@images/products/works-10.png"

import npr from "@images/npr-180x100.png"
import nike from "@images/nike-180x100.png"
import boomtown from "@images/logo-1.png"
import marvel from "@images/marvel-180x100.png"
import slide1 from "@images/products/slipper.png"
import slide2 from "@images/products/mugs.png"
import slide3 from "@images/products/laptopouch.png"
import slide4 from "@images/products/towel.png"
import slide5 from "@images/products/works-01.jpg"
import slide6 from "@images/products/works-02.jpg"
import slide7 from "@images/products/works-09.jpg"
import slide8 from "@images/products/works-03.png"
import slide9 from "@images/products/works-04.png"
import slide10 from "@images/products/works-05.png"
import slide11 from "@images/products/works-06.png"
import slide12 from "@images/products/works-07.png"
import slide13 from "@images/products/works-08.png"
import pattern1 from "@images/products/pattern1.png"
import pattern2 from "@images/products/pattern2.png"
import pattern3 from "@images/products/pattern3.png"
import quote from "@images/left-quote.svg"

class IndexPage extends React.Component {
	render() {
		const siteTitle = this.props.data.site.siteMetadata.title
		const siteDescription = this.props.data.site.siteMetadata.description
		// const posts = this.props.data.allSanityPost.edges
		// console.log(posts)
		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div id="hero">
					<div id="hero-banner-bg" />
					<div className="container h-100">
						<div
							className="col-lg-5 ml-auto d-lg-flex align-items-lg-center mb-lg-4"
							id="hero-banner-img"
						>
							<div className="content">
								<LazyLoadComponent>
									<HeroSwiper>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide1} alt="slide" />
										</div>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide2} alt="slide" />
										</div>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide3} alt="slide" />
										</div>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide4} alt="slide" />
										</div>
									</HeroSwiper>
								</LazyLoadComponent>
							</div>
						</div>
						<div className="row align-items-center h-100">
							<div className="col-lg-7">
								<div className="hero-content">
									<ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
										<h1 className="hero-title font-weight-bold text-white mb-4">
											Sell your ideas online in less than 5 minutes
										</h1>
										<p className="h5 text-light">
											Join others on The Social Marketplace and become a Now
											Merchant.
										</p>
										<div className="mt-4">
											<a
												className="btn btn-primary btn-inverted shadow arrow"
                                                //href="https://prints.buynow.io/#!sellnowioAdmin;src=buynow.io;choice=SIGNUP"
												//href="http://localhost:9999/#!sellnowioAdmin;src=buynow.io;choice=SIGNUP"
                                                href={`${process.env.GATSBY_PRINTS_BUYNOW_HOST}/#!sellnowioAdmin;src=buynow.io;choice=SIGNUP`}
												target="prints.buynow.io"
												//rel="noopener noreferrer"
											>
												Start Today!
											</a>
										</div>
									</ScrollAnimation>
								</div>
							</div>
						</div>
					</div>
				</div>

				<section id="about" className="component">
					<div className="container text-center">
						<div className="row justify-content-center mb-4">
							<div className="col-md-10">
								<div className="intro mb-4">
									<ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
										<h2 className="font-weight-bold">How It Works</h2>
									</ScrollAnimation>
								</div>

								<div className="py-3">
									<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
										<div className="steps-list-style py-4">
											<ul>
												<li>Upload</li>
												<li>Build</li>
												<li>Share</li>
												<li>Repeat</li>
											</ul>
										</div>
										<p>
											You set the profit for each image you upload and for each
											product you create. Build a new product and share it on
											Social Media in under 2 minutes. With over 100 products
											(and growing) to choose from, what are you waiting for?
										</p>
										<p>
											Your customers can choose from either Stripe or Paypal and
											be certain their transactions are secure. We've minimized
											the clicks without compromising security. Gone are the
											days in which your customers lose patience with a bad
											checkout process. Simple and secure.
										</p>
									</ScrollAnimation>
								</div>
							</div>
						</div>
						{/* */} <iframe
							title="video"
							src="https://player.vimeo.com/video/33031367?title=0&byline=0&portrait=0"
							frameBorder={0}
							allow="autoplay; fullscreen"
							allowFullScreen
						/> {/* */}
						<div className="mt-4 d-flex flex-column flex-md-row justify-content-center">
							{/* */} <Link
								to="/"
								className="btn btn-primary btn-inverted shadow arrow mr-3 mb-4 mb-md-0"
							>
								Watch and Learn
							</Link> {/* */}
							<a
								className="btn btn-primary btn-inverted shadow arrow mr-3 mb-4 mb-md-0"
								//href="https://prints.buynow.io/#!sellnowioAdmin;src=buynow.io;choice=SIGNUP"
								//href="http://localhost:9999/#!sellnowioAdmin;src=buynow.io;choice=SIGNUP"
                                href={`${process.env.GATSBY_PRINTS_BUYNOW_HOST}/#!sellnowioAdmin;src=buynow.io;choice=SIGNUP`}
								target="prints.buynow.io"
								//rel="noopener noreferrer"
							>
								Get Started
							</a>
						</div>
					</div>
				</section>
				<section id="sell-now" className="component bg-blue">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-md-10">
								<ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
									<div className="intro text-center">
										<h2 className="text-white font-weight-bold">
											Let's Get Started
										</h2>
									</div>
									<p className="text-white py-3">
										Use buynow.io to bring out your best. Whether you're an
										individual with a great idea, an organization looking to
										raise funds, a small or large business, our on-demand drop
										ship print service is a great match. Put your provocative
										idea, your funny quote or your latest marketing and branding
										message out there in print, for the world to see, in just a
										few minutes. Then sit back and let the world show their love
										and appreciation with the power of their wallets.
									</p>
								</ScrollAnimation>
							</div>
						</div>
						<div className="row align-items-stretch justify-content-center mt-5">
							<div className="col-md-4 mb-4">
								<ScrollAnimation
									animateIn="fadeInUp"
									className="h-100"
									animateOnce={true}
									delay={50}
								>
									<div className="content h-100 bg-white shadow rounded py-5 px-4 text-center">
										<a
											className="external-link"
											//href="https://prints.buynow.io/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_PRODUCT_SHOW_IMAGES;"
											//href="http://localhost:9999/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_PRODUCT_SHOW_IMAGES;"
                                            href={`${process.env.GATSBY_PRINTS_BUYNOW_HOST}/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_PRODUCT_SHOW_IMAGES`}
											//rel="noopener noreferrer"
											aria-label="upload and collaborate"
											target="prints.buynow.io"
										/>
										<LazyLoadImage
											effect="blur"
											className="img-fluid mb-3"
											src={upload}
											width={50}
											height={50}
											alt="Online"
											target="prints.buynow.io"
										/>
										<h4 className="mb-0 mt-2">Upload</h4>
										<p className="text-muted text-uppercase small">
											UPLOAD AND COLLABORATE
										</p>
										<p className="text-secondary">
											Do you have that perfect image? That incredible
											photograph? Your latest digital masterpiece? What are you
											waiting for? Turn it into a product and and turn it into
											the next big thing...in under 2 minutes. Don't believe it?
											Checkout this video!
										</p>
									</div>
								</ScrollAnimation>
							</div>
							<div className="col-md-4 mb-4">
								<ScrollAnimation
									animateIn="fadeInUp"
									className="h-100"
									animateOnce={true}
									delay={100}
								>
									<div className="content h-100 bg-white shadow rounded py-5 px-4 text-center">
										<a
											className="external-link"
											//href="https://prints.buynow.io/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_PRODUCT_MENU"
											//href="http://localhost:9999/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_PRODUCT_MENU;"
                                            href={`${process.env.GATSBY_PRINTS_BUYNOW_HOST}/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_PRODUCT_MENU`}
											//rel="noopener noreferrer"
											aria-label="super fast product creation"
											target="prints.buynow.io"
										/>
										<LazyLoadImage
											effect="blur"
											className="img-fluid mb-3"
											src={build1}
											width={50}
											height={50}
											alt="Online"
										/>
										<h4 className="mb-0 mt-2">Build</h4>
										<p className="text-muted text-uppercase small">
											SUPER FAST PRODUCT CREATION
										</p>
										<p className="text-secondary">
											Each image is worth a hundred products. Do you have that
											image that seems to resonate with people? Take a few
											minutes and create a product, or two, and see how your
											network reacts. Have just one of your products go viral
											and...boom!
										</p>
									</div>
								</ScrollAnimation>
							</div>
							<div className="col-md-4 mb-4">
								<ScrollAnimation
									animateIn="fadeInUp"
									className="h-100"
									animateOnce={true}
									delay={200}
								>
									<div className="content h-100 bg-white shadow rounded py-5 px-4 text-center">
										<a
											className="external-link"
											//href="https://prints.buynow.io/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_SHARE_PRODUCT"
											//href="http://localhost:9999/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_SHARE_PRODUCT;"
                                            href={`${process.env.GATSBY_PRINTS_BUYNOW_HOST}/#!sellnowioPrintfulWizard;src=buynow.io;choice=PRINTFUL_SHARE_PRODUCT`}
											//rel="noopener noreferrer"
											aria-label="share and post with ease"
											target="prints.buynow.io"
										/>
										<LazyLoadImage
											effect="blur"
											className="img-fluid mb-3"
											src={share}
											width={50}
											height={50}
											alt="Online"
										/>

										<h4 className="mb-0 mt-2">Share</h4>
										<p className="text-muted text-uppercase small">
											SHARE AND POST WITH EASE
										</p>

										<p className="text-secondary">
											If you build it, they will follow. If you share it, they
											will like it. Instagram, Facebook, Pinterest, Twitter,...
											Just a few clicks to get your great new product out in
											front of your network, the faithful followers, the masses.
										</p>
									</div>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</section>
				<section id="brand-marketing" className="component bg-white">
					<div className="container">
						<div className="row">
							<div className="col-md-7 mb-4">
								<ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
									<div className="intro mb-4">
										<h2 className="font-weight-bold mb-3">
											Image Collaboration
										</h2>
										<p className="text-uppercase text-muted">
											Any user can upload an image and mark it for
											collaboration.
										</p>
									</div>
									<p>
										When marking the image for collaboration, the user sets a
										markup price on the image, say <strong>$2.00.</strong>
									</p>
									<p>
										Any time another user uses another user's collaboration
										image in one of their products, then the total cost of the
										product is increased by the price (in this case{" "}
										<strong>$2.00</strong>). The user making the product can
										still set their markup price for the product, say{" "}
										<strong>$3.00</strong>.
									</p>
									<p>
										{" "}
										Now when a customer purchases this product, each user gets
										their markup price, in this case, the user sharing their
										image gets <strong>$2.00</strong> and the user using the
										shared image gets&nbsp;
										<strong>$3.00.</strong>
									</p>
								</ScrollAnimation>
							</div>
							<div className="col-md-5">
								<LazyLoadComponent>
									<Slider slidesperview={1}>
										<div className="swiper-slide">
											<img className="img-fluid" src={pattern1} alt="slide" />
										</div>
										<div className="swiper-slide">
											<img className="img-fluid" src={pattern2} alt="slide" />
										</div>
										<div className="swiper-slide">
											<img className="img-fluid" src={pattern3} alt="slide" />
										</div>
									</Slider>
								</LazyLoadComponent>
							</div>
						</div>
					</div>
				</section>

				<section id="sell-everywhere" className="component bg-dark-blue">
					<div className="container">
						<div className="row justify-content-center align-items-center">
							<div className="col-md-6 mb-4">
                                <BuynowItem/>
{/*
								<div className="content">
									<LazyLoadImage
										effect="blur"
										delayTime={500}
										className="img-fluid"
										src={works}
										alt="Works"
									/>
								</div>
*/ }
							</div>
							<div className="col-md-6">
								<ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
									<div className="intro mb-4">
										<h2 className="font-weight-bold mb-3 text-white">
											Sell Everywhere
										</h2>
										<p className="text-uppercase text-white-50">
											Art, Photography, Design &mdash; PUT IT OUT THERE
										</p>
									</div>
									<p className="font-weight-normal text-white">
										With our innovative technology, easily place one of your
										products, or any buynow.io product, on any web site or blog,
										with just a few simple updates. Our embedded product
										technology works well with today's modern web sites,
										providing a great solution to boost revenue opportunities,
										while keeping your audience where you want them, on your
										site!
									</p>
									<div className="mt-4">
										<a
											className="btn btn-primary btn-inverted shadow arrow"
											//href="https://prints.buynow.io/#!sellnowioAdmin;src=buynow.io;choice=SIGNUP"
											//href="http://localhost:9999/#!sellnowioAdmin;src=buynow.io;choice=SIGNUP"
                                            href={`${process.env.GATSBY_PRINTS_BUYNOW_HOST}/#!sellnowioAdmin;src=buynow.io;choice=SIGNUP`}
											target="prints.buynow.io"
											//rel="noopener noreferrer"
										>
											Start Today!
										</a>
									</div>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</section>

				<section id="clients" className="py-5 bg-dark">
					<div className="container">
						<div className="row">
							<div className="col-md-3 text-center">
								<ScrollAnimation animateIn="zoomIn" animateOnce={true}>
									<LazyLoadImage
										effect="blur"
										className="img-fluid"
										src={npr}
										alt="NPR"
									/>
								</ScrollAnimation>
							</div>
							<div className="col-md-3 text-center">
								<ScrollAnimation
									animateIn="zoomIn"
									animateOnce={true}
									delay={100}
								>
									<LazyLoadImage
										effect="blur"
										className="img-fluid"
										src={nike}
										alt="Nike"
									/>
								</ScrollAnimation>
							</div>
							<div className="col-md-3 text-center">
								<ScrollAnimation
									animateIn="zoomIn"
									animateOnce={true}
									delay={150}
								>
									<LazyLoadImage
										effect="blur"
										className="img-fluid"
										src={boomtown}
										alt="Boomtown"
									/>
								</ScrollAnimation>
							</div>
							<div className="col-md-3 text-center">
								<ScrollAnimation
									animateIn="zoomIn"
									animateOnce={true}
									delay={200}
								>
									<LazyLoadImage
										effect="blur"
										className="img-fluid"
										src={marvel}
										alt="Marvel"
									/>
								</ScrollAnimation>
							</div>
						</div>
					</div>
				</section>
				<section id="brand-marketing" className="component bg-white">
					<div className="container">
						<div className="row">
							<div className="col-md-7 mb-4">
								<ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
									<div className="intro mb-4">
										<h2 className="font-weight-bold mb-3">Brand Marketing</h2>
										<p className="text-uppercase text-muted">
											Put your marketing and your brand in motion
										</p>
									</div>
									<p>
										Whether you're a small business just getting started, a well
										established juggernaut, or somewhere in between, put your
										message where it counts the most - on people. Who better to
										advocate than your avid faithful? Everyone loves a great Tee
										shirt, so why not create a win for your existing customers
										while getting in front of your next customer? And don't stop
										with Tee shirts, we have over 150 other on-demand print
										products to customize your branding and messaging to appeal
										to any of your customers.
									</p>
								</ScrollAnimation>
							</div>
							<div className="col-md-5">
								<LazyLoadComponent>
									<Slider slidesperview={1}>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide5} alt="slide" />
										</div>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide6} alt="slide" />
										</div>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide7} alt="slide" />
										</div>
									</Slider>
								</LazyLoadComponent>
							</div>
						</div>
					</div>
				</section>
				<section id="artist-photographer" className="component bg-dark-blue">
					<div className="container">
						<div className="row">
							<div className="col-md-7 order-md-2">
								<ScrollAnimation animateIn="fadeInRight" animateOnce={true}>
									<div className="intro mb-4">
										<h2 className="font-weight-bold mb-3 text-white">
											Artists & Photographers
										</h2>
										<p className="text-uppercase text-white-50">
											Contributions from everyone, everywhere.
										</p>
									</div>
									<p className="text-light">
										We've made it easy for anyone to upload and share their
										work...and make money for doing it! For any image, design,
										photograph or quote that you upload, if you choose to mark
										it as 'collaborate', you can set your price and any time
										your image, that is, your property, is used in a product and
										purchased, you get paid the price you set. Power to the
										people!
									</p>
								</ScrollAnimation>
							</div>
							<div className="col-md-5 order-md-1">
								<LazyLoadComponent>
									<Slider slidesperview={1}>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide8} alt="slide" />
										</div>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide9} alt="slide" />
										</div>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide10} alt="slide" />
										</div>
									</Slider>
								</LazyLoadComponent>
							</div>
						</div>
					</div>
				</section>
				<section id="build-share-repeat" className="component bg-dark">
					<div className="container">
						<div className="row">
							<div className="col-md-7 mb-5">
								<ScrollAnimation animateIn="fadeInLeft" animateOnce={true}>
									<div className="intro mb-4">
										<h2 className="font-weight-bold mb-3 text-white">
											Build, Share, Sell, repeat
										</h2>
										<p className="text-uppercase text-white-50">
											Super simple, super powerful.
										</p>
									</div>
									<p className="text-light">
										You set the profit for each image you upload and for each
										product you create. Build a new product and share it on
										Social Media in under 2 minutes. With over 100 products (and
										growing) to choose from, what are you waiting for?
									</p>
									<div className="mt-4">
										<a
											className="btn btn-primary btn-inverted text-dark shadow arrow"
								            //href="https://prints.buynow.io/#!sellnowioAdmin;src=buynow.io;choice=SIGNUP"
            								//href="http://localhost:9999/#!sellnowioAdmin;src=buynow.io;choice=SIGNUP"
                                            href={`${process.env.GATSBY_PRINTS_BUYNOW_HOST}/#!sellnowioAdmin;src=buynow.io;choice=SIGNUP`}
		            						target="prints.buynow.io"
											//rel="noopener noreferrer"
										>
											Get Started
										</a>
									</div>
								</ScrollAnimation>
							</div>
							<div className="col-md-5">
								<LazyLoadComponent>
									<Slider slidesperview={1}>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide11} alt="slide" />
										</div>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide12} alt="slide" />
										</div>
										<div className="swiper-slide">
											<img className="img-fluid" src={slide13} alt="slide" />
										</div>
									</Slider>
								</LazyLoadComponent>
							</div>
						</div>
					</div>
				</section>
				<section id="quote" className="component bg-light">
					<div className="container">
						<ScrollAnimation animateIn="fadeIn" animateOnce={true}>
							<div className="row justify-content-md-end align-items-center mt-5">
								<div className="quote-img mb-4 mb-md-0">
									<LazyLoadImage
										effect="blur"
										className="img-fluid"
										src={quote}
										alt="quote"
									/>
								</div>
								<div className="col-md-8">
									<Slider slidesperview={1}>
										<div className="swiper-slide">
											<div className="quote-content px-5">
												<p className="blockquote mb-1">
													I don't care that they stole my idea. I care that they
													don't have any of their own.
												</p>
												<p className="text-dark">-- Nikola Tesla</p>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="quote-content px-5">
												<p className="blockquote mb-1">
													If there is anything that a man can do well, I say let
													him do it. Give him a chance.
												</p>
												<p className="text-dark">-- Abraham Lincoln</p>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="quote-content px-5">
												<p className="blockquote mb-1">
													There are no secrets to success. It is the result of
													preparation, hard work, and learning from failure.
												</p>
												<p className="text-dark">-- Colin Powel</p>
											</div>
										</div>
										<div className="swiper-slide">
											<div className="quote-content px-5">
												<p className="blockquote mb-1">
													If you cannot work with love but only with distaste,
													it is better that you should leave your work.
												</p>
												<p className="text-dark">-- Khalil Gibran</p>
											</div>
										</div>
									</Slider>
								</div>
							</div>
						</ScrollAnimation>
					</div>
				</section>
			</Layout>
		)
	}
}

export default IndexPage

export const indexPageQuery = graphql`
	query indexPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
