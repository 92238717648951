import React from "react"
import Swiper from "react-id-swiper"
import { Navigation } from "swiper/dist/js/swiper.esm"
import "react-id-swiper/lib/styles/css/swiper.css"

class SwiperHero extends React.Component {
	render() {
		const { children } = this.props
		const params = {
			modules: [Navigation],
			slidesPerView: 1,
			spaceBetween: 30,
			containerClass: "swiper-container pb-5",
			autoplay: true,
			pagination: {
				el: ".swiper-pagination",
				dynamicBullets: true,
				clickable: true,
			},
			navigation: {
				nextEl: "#swiper-button-next",
				prevEl: "#swiper-button-prev",
			},
		}

		return <Swiper {...params}>{children}</Swiper>
	}
}

export default SwiperHero
