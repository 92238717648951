import React, { Component } from 'react'
import Helmet from "react-helmet"
class BuynowItem extends Component {
  
  componentDidMount() {

    const s1=document.createElement('script')
    s1.src="https://code.jquery.com/jquery-3.5.1.min.js"
    s1.async=true;
    document.body.appendChild(s1)

    const s2=document.createElement('script')
    s2.src="https://buynow.io/js/buyitem.js"
    s2.async=true;
    document.body.appendChild(s2)

    const s3=document.createElement('link')
    s3.rel="stylesheet"
    s3.href="https://buynow.io/css/buyitem.css";
    document.body.appendChild(s3)

    //const s4=document.createElement('script')
    //s4.varname="componentUrl"
    //s4.value="https://magic.buynow.io";
    //document.body.appendChild(s4)
  }

  render() {

    return (
        <div className="buynow-item"><div id="B8D11A64-97D4-4139-B9FB-C75C8FDA0453"></div></div>
    )
  }
}
export default BuynowItem
